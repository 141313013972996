import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button, Image } from "react-bootstrap"
// import Img from "gatsby-image"

import BackgroundImage from 'gatsby-background-image'

import wovenWireCloth from "../images/index-grid/woven-wire-cloth.jpg"
import weldedWirePanels from "../images/index-grid/welded-wire-panels.jpg"
import weldedWireRolls from "../images/index-grid/welded-wire-rolls.jpg"

import wovenWireCloth2 from "../images/index-grid/woven-wire-cloth-2.jpg"
import weldedWirePanels2 from "../images/index-grid/welded-wire-panels-2.jpg"

const BackgroundSection = (props) => (
      <Layout>
        <SEO title="A Major Stocking Provider in Northern California" />

        <BackgroundImage
          Tag="section"
          className="masthead fixed"
          fluid={props.data.ticket.childImageSharp.fluid}
          fadeIn
        >
          <div className="black-overlay">
            <div className="content-box">
            <Container>
            <h1>A Major Stocking Provider in Northern California</h1>
            <Row>
              <Col lg={7}>
                <h2>Most Materials In-Stock for Immediate Shipping or Pickup</h2>
              </Col>
              <Col lg={5}>
                <ul>
                  <li>Manufactured in USA</li>
                  <li>DFARS Compliant – (avail upon request)</li>
                  <li>International Suppliers</li>
                </ul>
              </Col>
            </Row>
            <Button variant="outline-light" size="lg">Learn More</Button>
            </Container>
            </div>
          </div>          
        </BackgroundImage>
        <Container>
          <Row>
            <Col lg={4}>
              <h2>Woven Wire Cloth</h2>
              <p><Image src={wovenWireCloth} alt="Woven Wire Cloth mesh" fluid rounded /></p>
            </Col>
            <Col lg={4}>
              <h2>Welded Wire Panels</h2>
              <p><Image src={weldedWirePanels} alt="Welded Wire Panels" fluid rounded /></p>
            </Col>
            <Col lg={4}>
              <h2>Welded Wire Rolls</h2>
              <p><Image src={weldedWireRolls} alt="Welded Wire Rolls" fluid rounded /></p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h2>Woven Wire Cloth</h2>
              <p><Image src={wovenWireCloth2} alt="Woven Wire Cloth mesh" fluid rounded /></p>
            </Col>
            <Col lg={4}>
              <h2>Welded Wire Panels</h2>
              <p><Image src={weldedWirePanels2} alt="Welded Wire Panels" fluid rounded /></p>
            </Col>
            <Col lg={4}>
              <p><strong>Want more detailed information about our Wire Cloth products?</strong></p>
              <p>Contact Howard Wire Cloth in Hayward, CA at (800) 969-3559 for high-quality wire cloth and wire mesh at rates you can afford.</p>
              <p><Button variant="outline-secondary">Learn More</Button></p>
            </Col>
          </Row>
        </Container>
        </Layout>
)

export default BackgroundSection


export const pageQuery = graphql`
  query {
    ticket: file(relativePath: { eq: "golden-gate-from-above.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`